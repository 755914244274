import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {useSettings} from '../../../hooks/settings'
import settingsParams from '../../../settingsParams'
import {Description} from '../styled-components/description'
import {PageTitle} from '../styled-components/page-title'
import s from './header.scss'
import {HeaderProps} from '.'

export const Header = ({title, subtitle}: HeaderProps) => {
  const settings = useSettings()

  return (
    <div className={s.root}>
      <div className={s.title}>
        <PageTitle data-hook={DH.APP_HEADER_TITLE}>{settings.get(settingsParams.pageTitle) ?? title}</PageTitle>
      </div>
      <div className={s.subtitle}>
        <Description data-hook={DH.APP_HEADER_DESCRIPTION}>
          {settings.get(settingsParams.description) ?? subtitle}
        </Description>
      </div>
    </div>
  )
}
